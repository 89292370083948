import styled from 'styled-components'
import { colors } from 'theme'

const primaryStyle = {
  backgroundColor: '#f8d7da',
  borderColor: '#b8daff',
  color: '#004085',
}

const secondaryStyle = {
  backgroundColor: 'rgba(27, 81, 164, 0.10)',
  borderColor: '#d6d8db',
  color: '#1B51A4',
}

const dangerStyle = {
  backgroundColor: '#f8d7da',
  borderColor: '#f5c6cb',
  color: '#721c24',
}

const successStyle = {
  backgroundColor: '#d4edda',
  borderColor: '#c3e6cb',
  color: '#155724',
}

export const MessagesContainer = styled.div<{
  variant?: 'primary' | 'secondary' | 'success' | 'danger'
}>(
  {
    zIndex: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: 'black',
    borderColor: '#f5c6cb',
    borderRadius: '0.25rem',
    marginBottom: '10px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px',
  },
  ({ variant }) => variant === 'primary' && primaryStyle,
  ({ variant }) => variant === 'secondary' && secondaryStyle,
  ({ variant }) => variant === 'success' && successStyle,
  ({ variant }) => variant === 'danger' && dangerStyle,
)

export const AlertCircle = styled.img({
  width: 24,
  height: 24,
  color: colors.warning.DEFAULT,
})
