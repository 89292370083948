import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetLocationPartners } from 'hooks'

import { BreadcrumbContainer, LocationIcon, MainText, SubText } from './styles';

const routeNames: { [key: string]: string } = {
    /** Nested routes go first */
    '/analytics/locations': 'Locations',
    '/analytics/wow': 'Week Over Week',
    '/analytics/sales': 'Sales',
    '/analytics/orders': 'Orders',
    '/analytics/delivery-buy-down': 'Delivery Buy Down',
    '/analytics/product-mix': 'Product Mix',
    '/analytics/smart-pricing': 'Smart Pricing',
    '/analytics/reconciliation': 'Reconciliation Report',
    '/analytics/disputes': 'Disputes Report',
    '/analytics/advanced-orders': 'Advanced Orders',
    '/analytics/dm-reporting': 'Paid Ads Spend Overview',
    '/menu-management/menus/new': 'Create New Menu',

    /** Simple routes go here */
    '/analytics': 'Analytics',
    '/menu-management': 'Menu Management',
    '/manage-platform': 'Manage Platform',
    '/add-location': 'Add New Location',
    '/home': 'Home',
    '/billing': 'Billing',
    '/order-management': 'Order Management',
    '/digital-marketing': 'Digital Marketing',
    // Add additional routes as needed
};

const BreadcrumbHeader: React.FC = () => {
  const { pathname } = useLocation();
  const { locationId } = useParams<{ locationId: string }>();
  const { partner } = useGetLocationPartners(locationId)
  
  // Derive module name from route
  const currentPath = Object.keys(routeNames).find((key) => pathname.includes(key));

  const moduleName = currentPath
    ? routeNames[currentPath]
    : 'Unknown Module';

  const locationName = partner?.attributes?.name;
  
  return (
    <BreadcrumbContainer>
      <MainText>{moduleName}</MainText>
      <SubText>
        <LocationIcon />
        {locationName}
      </SubText>
    </BreadcrumbContainer>
  );
};

export default BreadcrumbHeader;
