import { getAllPartnerLocations } from 'api'
import { PartnerResource } from 'api/models'
import { ReactComponent as ChowlyNavLogo } from 'assets/icons/ChowlyNavImg.svg'
import clearIcon from 'assets/icons/clear-icon.svg'
import closeIcon from 'assets/icons/close-icon.svg'
import { ReactComponent as MoveLeftUp } from 'assets/icons/move-left-up.svg'
import { HamburgerButton, Spinner, UserMenu } from 'components'
import { Experiments } from 'config'
import { useGetLocationPartners, useIsMobile, useOpenModal, useRouteLocationId } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import { debounce } from 'lodash'
import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LocationSummary } from 'types/Partner'
import { showError } from 'utils'
import formatLocations from 'utils/formatLocations'

import Chevron_Down from '../../assets/icons/Chevron-Down.svg'
import * as Styled from './styles'

type NavLinkItem = {
  title: string
  route?: string
  href?: string
  target?: string
  popOut?: boolean
  icon?: ReactNode
  onClick?: () => void
  subNav?: Array<NavLinkItem>
}

const NavBar = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const locationId = useRouteLocationId()
  const { partner } = useGetLocationPartners(locationId)
  const { isMobile } = useIsMobile()
  const { logout, authorizedFeatures, user } = useAuth0()

  const [filteredLocations, setFilteredLocations] = useState<LocationSummary[] | undefined>([])
  const [locationsLoading, setLocationsLoading] = useState(false)
  const [leftNavSearchTerm, setLeftNavSearchTerm] = useState('')
  const [generalNavSearchTerm, setGeneralNavSearchTerm] = useState('')
  const [isNavBarOpen, setIsNavBarOpen] = useState(isMobile ? false : true) // in mobile don't open the nav bar by default. On web - do.
  const [selectedOption, setSelectedOption] = useState(partner?.attributes.name || '')
  const [showSelectedOption, setShowSelectedOption] = useState(true)
  const [showDropDown, setShowDropDown] = useState(false)
  const toggleNavBar = () => setIsNavBarOpen(!isNavBarOpen)
  const leftNavSearchInputRef = useRef<HTMLInputElement | null>(null)
  const generalNavSearchInputRef = useRef<HTMLInputElement | null>(null)
  const toolbarRef = useRef<HTMLDivElement | null>(null)

  const billingIsEnabled = authorizedFeatures?.find(
    (feature) => feature[Experiments.BILLING_NAVIGATION],
  )
  const isRccPlatform = authorizedFeatures?.find((feature) => feature[Experiments.RCC_PLATFORM])
  const isDigitalMarketing = authorizedFeatures?.find(
    (feature) => feature[Experiments.DIGITAL_MARKETING],
  )
  const omIsEnabled = authorizedFeatures?.find((feature) => feature[Experiments.ORDER_MANAGEMENT])
  const isAdminUser = user?.email?.includes('@chowlyinc.com')

  const openModal = useOpenModal()

  const navLinks: NavLinkItem[] = [
    {
      title: 'Home',
      route: `${locationId}/home`,
    },
    {
      title: 'Menu Management',
      route: `${locationId}/menu-management`,
    },
    ...(omIsEnabled
      ? [
          {
            title: 'Order Management',
            route: `${locationId}/order-management`,
          },
        ]
      : []),
    ...(isRccPlatform || isDigitalMarketing
      ? [
          {
            title: 'Digital Marketing',
            route: `${locationId}/digital-marketing`,
          },
        ]
      : []),
    {
      title: 'Analytics',
      route: `${locationId}/analytics`,
    },
  ]

  const manageAccountLinks: NavLinkItem[] = [
    ...(billingIsEnabled
      ? [
          {
            title: 'Billing',
            route: `${locationId}/billing`,
          },
        ]
      : []),
    {
      title: 'Account Management',
      onClick: () => {
        openModal('ManageAccount')
      },
    },
    {
      title: 'Help Center',
      onClick: () => {
        window.open('https://chowly.help/s/', '_blank')
      },
      icon: <MoveLeftUp />,
    },
    {
      title: 'Log Out',
      onClick: () => {
        logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
      },
    },
  ]

  const resetInput = () => {
    setLeftNavSearchTerm('')
    setGeneralNavSearchTerm('')
    setFilteredLocations([])
    if (leftNavSearchInputRef.current === document.activeElement) {
      leftNavSearchInputRef.current?.focus()
    } else if (generalNavSearchInputRef.current === document.activeElement) {
      generalNavSearchInputRef.current?.focus()
    }
  }

  const fetchFilteredLocations = async (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value

    // if empty, clear out filtered locations
    if (val === '') {
      setFilteredLocations([])
    } else {
      const searchTerm = String(val.trim())

      let data: PartnerResource[] = []

      try {
        const partnerLocationsRes = await getAllPartnerLocations(undefined, searchTerm)

        if (partnerLocationsRes) {
          data = partnerLocationsRes.data
        }
      } catch (err) {
        showError(err)
      }

      if (data) {
        const _locations = formatLocations(data)
        setFilteredLocations(_locations)
      }
    }

    setLocationsLoading(false)
  }

  const functionDebounce = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      fetchFilteredLocations(event)
    }, 300),
    [],
  )

  const handleGeneralNavChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLocationsLoading(true)
    setGeneralNavSearchTerm(event.target.value)
    functionDebounce(event)
  }

  const handleLeftNavChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLocationsLoading(true)
    setLeftNavSearchTerm(event.target.value)
    functionDebounce(event)
  }

  const handleDropDownClick = (toggleDropDown: boolean) => {
    if (!isAdminUser) return
    toggleDropDown && setShowDropDown(!showDropDown)
    // mock event to simulate e.target.value (the param type for functionDebounce)
    const event = { target: { value: ' ' } } as ChangeEvent<HTMLInputElement>
    functionDebounce(event)
    setLocationsLoading(true)
  }

  useEffect(() => {
    // when dropdown is open && no search term is entered, return all location results by setting e.target.value to ' '
    const val = showDropDown && !generalNavSearchTerm.length ? ' ' : generalNavSearchTerm
    const event = { target: { value: val } } as ChangeEvent<HTMLInputElement>
    functionDebounce(event)
  }, [showDropDown, generalNavSearchTerm])

  const replacePathName = (newLocationId: string) =>
    pathname.includes('analytics')
      ? `/${newLocationId}/menu-management`
      : pathname.replace(/\/\d+\//, `/${newLocationId}/`)

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const changeSelectHandler = (selectedOption: any, closeNavBar?: boolean) => {
    setShowDropDown(false)
    setSelectedOption(selectedOption.name)
    if (selectedOption?.id) {
      navigate(replacePathName(selectedOption?.id))
      resetInput()
      if (closeNavBar) toggleNavBar()
    }
  }

  useEffect(() => setSelectedOption(partner?.attributes?.name || ''), [partner?.attributes?.name])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!generalNavSearchInputRef.current?.value.length && toolbarRef.current && !toolbarRef.current.contains(event.target as Node)) {
        setShowDropDown(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {/* Commenting out as we might need this in the future */}
      {/* {isNavBarOpen && <Styled.Backdrop {...(isMobile && { onClick: toggleNavBar })} />} */}

      {/* General Header for Mobile */}
      <Styled.MobileContainer>
        <HamburgerButton onClick={toggleNavBar} />
        <Styled.LogoLinkMobile to='/'>
          <ChowlyNavLogo />
        </Styled.LogoLinkMobile>
      </Styled.MobileContainer>

      {/* General Header */}
      <Styled.Container>
        <Styled.Header>
          <HamburgerButton onClick={toggleNavBar} />
          <Styled.LogoLink to={`${locationId}/home`}>
            <ChowlyNavLogo />
          </Styled.LogoLink>
        </Styled.Header>

        <Styled.ToolBarHeader ref={toolbarRef}>
          <Styled.SearchWrapperHeader>
            <Styled.Search
              type='text'
              placeholder={showSelectedOption ? selectedOption || 'Location Name' : ''}
              value={generalNavSearchTerm}
              onChange={handleGeneralNavChange}
              onClick={() => {
                setShowSelectedOption(false)
                if (isAdminUser && !generalNavSearchTerm.length) {
                  setShowDropDown(true)
                  handleDropDownClick(false)
                }
              }}
              onBlur={() => setShowSelectedOption(true)}
              ref={generalNavSearchInputRef}
            />
            {isAdminUser && (
              <Styled.ClearButton
                src={Chevron_Down}
                alt='closeIcon'
                onClick={() => handleDropDownClick(true)}
              />
            )}
            {!!generalNavSearchTerm.length && (
              <Styled.ClearButton alt='cancel' src={clearIcon} onClick={resetInput} />
            )}
            {((!!filteredLocations?.length && !!generalNavSearchTerm.length) ||
              (!!filteredLocations?.length && showDropDown)) && (
              <Styled.SearchResults>
                {filteredLocations.map((location, index) => (
                  <Styled.Result key={index} onClick={() => changeSelectHandler(location)}>
                    {location.name}
                  </Styled.Result>
                ))}
              </Styled.SearchResults>
            )}
            {((!filteredLocations?.length && !!generalNavSearchTerm.length) ||
              (showDropDown && !filteredLocations?.length)) && (
              <Styled.SearchResults>
                <Styled.Result>
                  {locationsLoading ? <Spinner /> : 'No results found.'}
                </Styled.Result>
              </Styled.SearchResults>
            )}
          </Styled.SearchWrapperHeader>
        </Styled.ToolBarHeader>
      </Styled.Container>

      {/* Left SideBar */}
      <Styled.SideBar isOpen={isNavBarOpen}>
        <Styled.CloseButton alt='cancel' src={closeIcon} onClick={toggleNavBar} />
        <Styled.ToolBar>
          <Styled.SearchWrapper>
            <Styled.Search
              type='text'
              placeholder={selectedOption || 'Location Name'}
              value={leftNavSearchTerm}
              onChange={handleLeftNavChange}
              ref={leftNavSearchInputRef}
            />
            {!!leftNavSearchTerm.length && (
              <Styled.ClearButton alt='cancel' src={clearIcon} onClick={resetInput} />
            )}
            {!!filteredLocations?.length && !!leftNavSearchTerm.length && (
              <Styled.SearchResults>
                {filteredLocations.map((location, index) => (
                  <Styled.Result key={index} onClick={() => changeSelectHandler(location, true)}>
                    {location.name}
                  </Styled.Result>
                ))}
              </Styled.SearchResults>
            )}
            {filteredLocations?.length === 0 && !!leftNavSearchTerm.length && (
              <Styled.SearchResults>
                <Styled.Result>
                  {locationsLoading ? <Spinner /> : 'No results found.'}
                </Styled.Result>
              </Styled.SearchResults>
            )}
          </Styled.SearchWrapper>
          <Styled.Nav>
            {navLinks.map((link, linkIndex) => (
              <Styled.NavLink
                key={linkIndex}
                to={link.route ?? ''}
                {...(isMobile && { onClick: toggleNavBar })}
              >
                <Styled.NavLinkText>
                  <span>{link.title}</span>
                </Styled.NavLinkText>
              </Styled.NavLink>
            ))}
          </Styled.Nav>
          <UserMenu />
          <Styled.ManageAccount>
            {manageAccountLinks.map((link, linkIndex) => (
              <Styled.ManageLink key={linkIndex} to={link.route ?? '#'} onClick={link.onClick}>
                <Styled.NavLinkText>
                  {link.title === 'Log Out' ? (
                    <Styled.Logout>{link.title}</Styled.Logout>
                  ) : (
                    <span>
                      {link.title}
                      {link?.icon}
                    </span>
                  )}
                </Styled.NavLinkText>
              </Styled.ManageLink>
            ))}
          </Styled.ManageAccount>
        </Styled.ToolBar>
      </Styled.SideBar>
    </>
  )
}

export default NavBar
