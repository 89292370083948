import styled from '@emotion/styled'
import { DialogContent } from '@mui/material'
import { ReactComponent as CheckIcon } from 'assets/icons/ellipse-checked.svg'
import PrimaryButton from 'components/modern/PrimaryButton'
import moment from 'moment'
import React from 'react'

import { InsensitiveBackDropModal } from '../components'
import { BasicHolidayHourConfig } from '../style'

type SuccessModalProps = {
  holiday: BasicHolidayHourConfig | null
  onClose: () => void
}

const Container = styled(InsensitiveBackDropModal)`
  & .MuiBackdrop-root {
    background-color: #fafafacc;
  }

  & .MuiDialog-paper {
    border-radius: 8px;
    max-width: 667px;
    padding: 32px;
  }

  @media (min-width: 667px) {
    & .MuiDialog-paper {
      width: 667px;
    }
  }
`

const Content = styled(DialogContent)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 0;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Title = styled.h1`
  color: #292929;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`

const Description = styled.div`
  color: #292929;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const IconWrapper = styled.div`
  & svg {
    width: 24px;
    height: 24px;
    fill: #32965d;

    circle {
      fill: inherit;
    }
  }
`

const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  button {
    margin-left: -10px;
  }
`

const SuccessModal = ({ holiday, onClose }: SuccessModalProps) => {
  const open = !!holiday

  return (
    <Container open={open} onClose={onClose}>
      <Content>
        <IconWrapper>
          <CheckIcon color='success' />
        </IconWrapper>
        <Body>
          <Title>Holiday Hours Added</Title>
          <Description>
            {holiday?.timePeriod ? (
              <>
                The location will be open from {holiday.timePeriod.startTime} to{' '}
                {holiday.timePeriod.endTime} on {moment(holiday?.date).format('MMM DD, YYYY')}, this
                can take up to 5 minutes to show up on your ordering platforms.
              </>
            ) : (
              <>
                The location will be closed all day on{' '}
                {moment(holiday?.date).format('MMM DD, YYYY')}, this can take up to 5 minutes to
                show up on your ordering platforms.
              </>
            )}
          </Description>

          <Actions>
            <PrimaryButton variant='text' onClick={onClose}>
              Close
            </PrimaryButton>
          </Actions>
        </Body>
      </Content>
    </Container>
  )
}

export default SuccessModal
