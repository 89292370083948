import { generatePath } from 'react-router'
import { chowlyApi } from 'config'
import client from '../chowly'
import { 
  HolidayHourCreateInput, 
  HolidayHourResponse, 
  HolidayHoursListResponse 
} from '../models'

// GET /api/v3/partners/:partner_id/holiday_hours
export const getHolidayHours = async (partnerId: string) => {
  const url = generatePath(chowlyApi.partnerHolidayHours, { partnerId })
  const { data } = await client.get<HolidayHoursListResponse>(url, {
    params: { include: 'time_periods' }
  })
  return data
}

// POST /api/v3/partners/:partner_id/holiday_hours
export const createHolidayHours = async (
  partnerId: string,
  payload: HolidayHourCreateInput
) => {
  const url = generatePath(chowlyApi.partnerHolidayHours, { partnerId })
  const { data } = await client.post<HolidayHourResponse>(url, payload)
  return data
}

// DELETE /api/v3/partners/:partner_id/holiday_hours/:id
export const deleteHolidayHours = async (
  partnerId: string,
  holidayHoursId: string
) => {
  const url = generatePath(chowlyApi.partnerHolidayHour, { 
    partnerId,
    id: holidayHoursId 
  })
  return client.delete(url)
} 