import { Form as FormDefault } from 'formik'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { breakpoints, colors } from 'theme'

export const Container = styled.div({
  display: 'flex',
  width: 600,
  padding: 32,
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 24,
  borderRadius: 8,
  background: '#FEFEFE',
  boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.07)',
})

export const Title = styled.h2({
  color: '#292929',
  fontWeight: '600',
  fontSize: 20,
  lineHeight: '140%',
})

export const Form = styled(FormDefault)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  paddingTop: '0.75rem',
  [`@media ${breakpoints.sm}`]: {
    paddingTop: '1rem',
  },
})

export const FieldsWrap = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
})

export const ButtonDiv = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
})

export const Subtitle = styled.h4({
  fontSize: 13,
  lineHeight: '150%',
  fontWeight: 400,
  marginTop: 5,
})

export const SentMessage = styled.h4({
  fontSize: '0.9rem',
  lineHeight: '2rem',
  fontWeight: 400,
  margin: '10px 70px 10px 50px',
  marginTop: '1rem',
})

export const Header = styled.div({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'center',
  margin: '30px 0px 20px 20px',
})

export const LoginLink = styled(Link)({
  color: colors.primary.DEFAULT,
  fontWeight: 600,
  fontSize: '1.2rem',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  width: '100px',
  border: '1px solid #00296b',
})

export const FormContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})
