import { getAllPartnerLocations, getPartner } from 'api/partners'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { activePartnerIdState } from 'state/atoms'

export const checkValidLocationId = (locationId: string | undefined) => {
  if (!locationId || locationId === 'null' || locationId === 'undefined') {
    return false
  }
  return true
}

const useRouteLocationId = () => {
  const { locationId } = useParams()
  const navigate = useNavigate()

  const [activePartnerId, setActivePartnerId] = useRecoilState(activePartnerIdState)

  const fetchDefaultLocation = async () => {
    try {
      const { data } = await getAllPartnerLocations()

      if (data?.length) {
        data.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
        const firstLocId = data[0]?.id

        setActivePartnerId(firstLocId)
        navigate(`/${firstLocId}/home`)
      }
    } catch (err) {
      console.error('Invalid Location Id', err)
      // We can't fetch default locations. Show Not Found page
      navigate('/')
    }
  }

  const fetchLocation = async (locationId: string) => {
    if (locationId === ':locationId') {
      return
    }

    try {
      const location = await getPartner(locationId)

      if (location?.data) {
        setActivePartnerId(locationId)
      }
    } catch (err) {
      // this id wasn't valid or we don't have the right permissions. Fetch the default location and set that instead.
      console.error('Invalid Location Id', err)
      fetchDefaultLocation()
    }
  }

  useEffect(() => {
    const isValidLocationId = checkValidLocationId(locationId)

    if (!isValidLocationId && !activePartnerId) {
      // fetch the first alphanumeric location and set that to recoil state
      fetchDefaultLocation()
      // this happens if we redirect to a page with no set location, or if we just login to the application
    } else if (!isValidLocationId && activePartnerId) {
      // this happens if we are missing the locationId from the url for some reason. Reset params to whatever the global state object has.
      // set params to be the activePartnerId
      navigate(`/${activePartnerId}/home`)
    } else if (locationId && isValidLocationId && activePartnerId !== locationId) {
      // we are changing locationId, make sure this is a location that we have access to and set to global state
      // then we're trying to change locations
      fetchLocation(locationId)
    }
  }, [locationId, activePartnerId])

  return activePartnerId
}

export default useRouteLocationId
