import { Experiments } from 'config'
import { useAuth0 } from 'libs/auth0-react'

/**
 * Hook to check if a user has a specific authorized feature.
 * @param feature - The feature to check for.
 * @returns True if the user has the feature, false otherwise.
 */
const useHasAuthorizedFeature = (feature: Experiments) => {
  const { authorizedFeatures } = useAuth0()
  return authorizedFeatures?.find((f) => f[feature])
}

export default useHasAuthorizedFeature
