import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'
import { useNavigate } from 'react-router-dom'

import Button from '../Button/Button'
import * as Styled from './styles'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Styled.Container>
      <Styled.Box>
        <ChowlyLogo />

        <Styled.Title>
          <Styled.Red>Oops!</Styled.Red> Page not found
        </Styled.Title>

        <Styled.Description>The page you’re looking for doesn’t exist.</Styled.Description>

        <Button onClick={() => navigate('/')}>Go home</Button>
      </Styled.Box>
    </Styled.Container>
  )
}

export default NotFound
