import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  CarouselContainer,
  CarouselTitle,
  SlideContainer,
  StyledCard,
  NavigationContainer,
  StyledIconButton,
  Indicator,
} from './styles';
import OrdersComeFromReport from './OrdersComeFromReport';
import RepeatCustomersAddedReport from './RepeatCustomersAddedReport';
import TopCustomersListReport from './TopCustomersListReport';

type Props = {
  partnerId: string
}

const MetricsCarousel = ({ partnerId }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const metricGroups = [
    [
      <OrdersComeFromReport key="orders-come-from-report" partnerId={partnerId || ''} />,
    ],
    [
      <RepeatCustomersAddedReport key="repeat-customers-added-report" partnerId={partnerId || ''} />,
    ],
    [
      <TopCustomersListReport key="top-customers-list-report" partnerId={partnerId || ''} />,
    ],
  ];

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? metricGroups.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev === metricGroups.length - 1 ? 0 : prev + 1));
  };

  const handleIndicatorClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <CarouselContainer>
      <CarouselTitle>Metrics</CarouselTitle>

      {/* Carousel */}
      <Carousel
        autoPlay={false}
        indicators={false}
        animation="slide"
        navButtonsAlwaysInvisible
        index={activeIndex}
        onChange={(index) => setActiveIndex(index as number)}
      >
        {metricGroups.map((group, index) => (
          <SlideContainer key={index}>
            {group.map((metric, idx) => (
              <StyledCard key={idx}>
                {metric}
              </StyledCard>
            ))}
          </SlideContainer>
        ))}
      </Carousel>

      {/* Navigation Buttons and Indicators */}
      <NavigationContainer isMobile={isMobile}>
        <StyledIconButton onClick={handlePrev} data-testid="navigate-previous">
          <NavigateBefore fontSize="small" sx={{ color: '#1B51A4', width: '16px' }} />
        </StyledIconButton>

        {metricGroups.map((_, idx) => (
          <Indicator
            key={idx}
            active={activeIndex === idx}
            onClick={() => handleIndicatorClick(idx)}
          />
        ))}

        <StyledIconButton onClick={handleNext} data-testid="navigate-next">
          <NavigateNext fontSize="small" sx={{ color: '#1B51A4', width: '16px' }} />
        </StyledIconButton>
      </NavigationContainer>
    </CarouselContainer>
  );
};

export default MetricsCarousel;
