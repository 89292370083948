import styled from 'styled-components'
import { borderRadius, colors } from 'theme'

export const Field = styled.div({
  marginBottom: '1.25rem',
})

export const FieldLabel = styled.label({
  display: 'block',
  fontSize: '1rem',
  fontWeight: '700',
  color: colors.primary.light,
  marginBottom: '0.25rem',
})

export const Input = styled.input(
  {
    width: '100%',
    padding: '0.75rem',
    backgroundColor: colors.white,
    appearance: 'none',
    borderWidth: 1,
    borderColor: colors.gray.dark,
    borderRadius: borderRadius.lg,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '700',
  },
  ({ hasError }) =>
    hasError && {
      borderColor: colors.red.DEFAULT,
    },
)

export const FieldError = styled.span({
  color: colors.red.DEFAULT,
  marginBottom: '0.5rem',
})

// Change Password
export const FieldChangePassword = styled.div({
  width: '100%',
  marginBottom: '1.25rem',
})

export const FieldLabelChangePassword = styled.label({
  display: 'block',
  fontSize: 13,
  fontWeight: '700',
  color: '#292929',
  margin: '10px 0',
})

export const InputChangePassword = styled.input(
  {
    width: '100%',
    padding: '0.75rem',
    backgroundColor: colors.white,
    appearance: 'none',
    borderWidth: 1,
    borderColor: colors.gray.dark,
    borderRadius: borderRadius.lg,
    fontSize: 15,
    lineHeight: '1.5rem',
    fontWeight: '400',
  },
  ({ hasError }) =>
    hasError && {
      borderColor: colors.red.DEFAULT,
    },
)

export const FieldErrorChangePassword = styled.span({
  fontSize: 14,
  color: colors.red.DEFAULT,
  marginBottom: '0.5rem',
})