import { OrderReportResponse } from 'api/orderManagement/types'
import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from '../chowly'

export const getOrderReport = async ({
  partnerId,
  link = null,
  limit = 15,
  filter = '',
}: {
  partnerId: string
  link: string | null
  limit: number
  filter: string
}) => {
  const page = 1

  const endpoint = link || generatePath(chowlyApi.orderManagement)

  const { data } = await client.get<OrderReportResponse>(endpoint, {
    params: !link ? { partner_id: partnerId, page, limit, filter } : undefined,
  })

  return data
}

export const refundOrder = async (orderId: number): Promise<void> => {
  const endpoint = generatePath(chowlyApi.refundOrder)

  try {
    const response = await client.put<void>(endpoint, { id: orderId })

    if (response.status === 204) {
      toast.success(`Order ${orderId} refunded successfully.`)
    }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      switch (status) {
        case 400:
          toast.error(
            `Order ${orderId} is not refundable. Reason: ${data?.message || 'Unknown reason'}.`,
          )
          break
        case 404:
          toast.error(`Order ${orderId} not found.`)
          break
        case 403:
          toast.error(`Forbidden: this order cannot be refunded: ${orderId}.`)
          break
        default:
          toast.error(`An unexpected error occurred: ${status}`)
      }
    } else {
      toast.error(`A network error occurred: ${error.message}`)
    }
  }
}

export const cancelOrder = async (orderId: number): Promise<void> => {
  const endpoint = generatePath(chowlyApi.cancelOrder)

  try {
    const response = await client.put<void>(endpoint, { id: orderId })

    if (response.status === 204) {
      toast.success(`Order ${orderId} cancelled successfully.`)
    }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response

      switch (status) {
        case 400:
          toast.error(
            `Order ${orderId} is not cancellable. Reason: ${data?.message || 'Unknown reason'}.`,
          )
          break
        case 404:
          toast.error(`Order ${orderId} not found.`)
          break
        case 403:
          toast.error(`Forbidden: this order cannot be cancelled.`)
          break
        default:
          toast.error(`An unexpected error occurred: ${status}`)
      }
    } else {
      toast.error(`A network error occurred: ${error.message}`)
    }
  }
}
