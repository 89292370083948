import React, { useState } from 'react';
import styled from 'styled-components';

interface StyledSpanProps {
  customStyles?: React.CSSProperties;
}

const TruncatedSpan = styled.span<StyledSpanProps>(props => ({
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8
  },
  ...props.customStyles
}));

interface TruncatedProps {
  text: string;
  length?: number;
  position?: 'start' | 'end';
  customStyles?: React.CSSProperties;
  className?: string;
}

const Truncated = ({
  text,
  length = 8,
  position = 'end',
  customStyles = {},
  className = ''
}: TruncatedProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!text) return null;

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const getTruncatedValue = (str: string) => {
    if (position === 'end') {
      return `...${str.slice(-length)}`;
    } else {
      return `${str.slice(0, length)}...`;
    }
  };

  const displayValue = isExpanded ? text : getTruncatedValue(text);

  return (
    <TruncatedSpan
      onClick={toggleExpand}
      customStyles={customStyles}
      className={className}
    >
      {displayValue}
    </TruncatedSpan>
  );
};

export default Truncated;