import { NavBar } from 'components'
import { env } from 'config'
import ModalManger from 'features/modal/ModalManager'
import { useGetPartnerLocations, useRouteLocationId } from 'hooks'
import { LocationsData } from 'hooks/useGetPartnerLocations'
import { useAuth0 } from 'libs/auth0-react'
import { Suspense, useEffect, useRef } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  isMenuImportEnqueuedState,
  menuImportSuccessfulState,
  menuImportTimestampState,
  menuPlatformsState,
  onboardingStatusWebHookCall,
  orderPlatformConnectedStatus,
  uberPublishSuccessfulWebHookCall,
  userIdState,
} from 'state/atoms'
import { identifyUser } from 'utils/eventTracker'

import actionCableConnection from '../../features/websockets/connection'
import * as Styled from './styles'
import BreadcrumbHeader from 'components/BreadcrumbHeader/BreadcrumbHeader'

type ContextType = {
  locationsData: LocationsData
  refetchLocations: (page?: number, query?: string) => Promise<void>
}

const MainLayout = () => {
  const subscriptionRef = useRef()
  const { accessToken, user } = useAuth0()
  const locationId = useRouteLocationId()

  const setMenuImportTimestamp = useSetRecoilState(menuImportTimestampState)
  const setMenuImportEnqueued = useSetRecoilState(isMenuImportEnqueuedState)
  const setMenuPlatforms = useSetRecoilState(menuPlatformsState)
  const setMenuImportSuccessful = useSetRecoilState(menuImportSuccessfulState)
  const setDoorDashOnboardingStatusWebHook = useSetRecoilState(onboardingStatusWebHookCall)
  const setUberEatsPublishSuccessfulWebHook = useSetRecoilState(uberPublishSuccessfulWebHookCall)
  const setOrderPlatformConnectedStatus = useSetRecoilState(orderPlatformConnectedStatus)
  const userId = useRecoilValue(userIdState)
  const [locationsData, refetch] = useGetPartnerLocations()

  interceptLoaded()

  useEffect(() => {
    if (typeof locationId === 'string') {
      actionCableConnection(
        subscriptionRef,
        user,
        accessToken,
        locationId,
        setMenuImportTimestamp,
        setMenuImportEnqueued,
        setMenuPlatforms,
        setMenuImportSuccessful,
        setDoorDashOnboardingStatusWebHook,
        // @ts-ignore
        setUberEatsPublishSuccessfulWebHook,
        setOrderPlatformConnectedStatus,
      )
    }
  }, [locationId])

  useEffect(() => {
    if (user) {
      identifyUser(user, Number(userId))
    }
  }, [user])

  async function interceptLoaded() {
    if (window.QSI?.API) {
      window.QSI.API.unload()
      if (user?.name) {
        window.QSI.config.firstName = user.name
      }
      if (userId) {
        window.QSI.config.externalReference = userId
      }
      if (env && user?.email) {
        window.QSI.config.contactEmbeddedData = { environment: env, email: user.email }
      }
      await window.QSI.API.load()
      window.QSI.API.run()
    }
  }

  useEffect(() => {
    window.addEventListener('qsi_js_loaded', interceptLoaded, false)
  }, [])

  return (
    <Styled.Container data-testid='main-layout-container'>
      <ModalManger />
      <NavBar />
      {/* <Styled.Aside>
        <NavBar />
      </Styled.Aside> */}
      <Suspense fallback={<Styled.LoadingContent>Loading...</Styled.LoadingContent>}>
        <Styled.Main>
          {/* <NewNavHeader /> */}
          <BreadcrumbHeader />
          <Outlet context={{ locationsData, refetchLocations: refetch } satisfies ContextType} />
        </Styled.Main>
      </Suspense>
      <div hidden id='user-email'>
        {user?.email}
      </div>
      <div hidden id='user-id'>
        {user?.id}
      </div>
    </Styled.Container>
  )
}

export default MainLayout

// this lets us make the getAllPartnerLocations call once and reuse it with the outlet context
export const useLocations = () => {
  return useOutletContext<ContextType>()
}
