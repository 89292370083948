import { HowToVideoModal } from 'components'
import { AddPlatformModal } from 'components/AddPlatformModal'
import ManageAccount from 'components/ManageAccount/ManageAccount'
import { useCloseModal } from 'hooks'
import { useRecoilValue } from 'recoil'
import { modalState } from 'state/atoms'

import Modal from './Modal'

const ModalManger = () => {
  const closeModal = useCloseModal()
  const { isOpen, modalName } = useRecoilValue(modalState)
  const defaultWidth = '90%'

  const getWidth = (modalName: string | null) => {
    switch (modalName) {
      case 'AddPlatform':
      case 'ManageAccount':
        return 'auto'
      default:
        return defaultWidth
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (modalName === 'ManageAccount') {
          closeModal()
        }
      }}
      width={getWidth(modalName)}
    >
      {renderModal(modalName)}
    </Modal>
  )
}

const renderModal = (modalName: string | null) => {
  switch (modalName) {
    case 'HowToVideoModal':
      return <HowToVideoModal />
    case 'ManageAccount':
      return <ManageAccount />
    case 'AddPlatform':
      return <AddPlatformModal />
    default:
      return <></>
  }
}

export default ModalManger
