import styled from '@emotion/styled'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { FormControlLabel, Radio } from '@mui/material'
import TimePicker from 'rc-time-picker'
// @ts-ignore
import DatePicker from 'react-datepicker'

export const ModalContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

export const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
`

export const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d7d7d7;
  width: 100%;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

export const SectionLabel = styled.div`
  color: #777;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
`

export const CustomTimeSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
`

export const FormElement = styled(FormControlLabel)`
  margin-right: 0;
  width: fit-content;

  & .MuiFormControlLabel-label {
    color: #292929;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`

export const StyledRadio = styled(Radio)`
  & svg {
    width: 20px;
    height: 20px;
  }
`

export const TimePickersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledTimePicker = styled(TimePicker)<{ error?: boolean }>`
  height: 33px;
  width: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & .rc-time-picker-input {
    width: 84px;
    padding: 8px 12px;
    color: #484848;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    border: 1px solid ${({ error }) => (error ? '#C13030' : '#D7D7D7')};
    &:focus {
      outline: none;
      border: 1px solid ${({ error }) => (error ? '#C13030' : '#D7D7D7')};
      box-shadow: none;
    }
  }
`

export const DatePickerWrapper = styled.div`
  width: 113px;
  input {
    padding: 8px 12px;
  }

  .react-datepicker-wrapper > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const DateInput = styled(DatePicker)<{ error?: boolean }>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid ${({ error }) => (error ? '#C13030' : '#D7D7D7')};
  width: 100%;

  color: #484848;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &:focus {
    outline: none;
    border: 1px solid ${({ error }) => (error ? '#C13030' : '#D7D7D7')};
    box-shadow: none;
  }
`

export const ExpandMoreIcon = styled(ExpandMoreRoundedIcon)`
  color: #d7d7d7;
  transform: translateX(calc(-100% - 6px));
`

export const WarningTimeLabel = styled.div`
  color: #c13030;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const DateAlreadySetLabel = styled.div`
  color: #c13030;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const ClearIcon = styled(CloseRoundedIcon)`
  color: #d7d7d7;
  width: 18px;
  height: 18px;
  transform: translateX(calc(-100% - 6px));
  cursor: pointer;
`
