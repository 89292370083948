import { DialogProps } from '@mui/material'

import { BaseModal } from './style'

/** A Base Modal that ignores backdrop clicks */
export const InsensitiveBackDropModal = (props: DialogProps) => {
  const { onClose, ...rest } = props

  const handleClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return
    onClose && onClose(event, reason)
  }

  return <BaseModal onClose={handleClose} {...rest} />
}
