import moment from 'moment'
import { LocationSummary } from 'types/Partner'
import { convertClientToPartnerTimezone } from 'utils'
import { getLocationTimezone } from 'utils/dmUtils'

const getCurrentTime = (location?: LocationSummary) => {
  const partnerTimezone = getLocationTimezone(location)
  const now = convertClientToPartnerTimezone(new Date().toISOString(), partnerTimezone)
  return now
}

// Get tomorrow's date in partner's timezone
const getTomorrowDate = (location?: LocationSummary) => {
  const today = getCurrentTime(location)
  const tomorrow = moment(today).add(1, 'day').startOf('day').toDate()
  return tomorrow
}

export { getCurrentTime, getTomorrowDate }
