import { StatusResource } from 'api'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import { ButtonWithConfirmationDialog, SentenceLoader } from 'components'
import { useCallback, Children } from 'react'
import { formatDate } from 'utils'
import _ from 'lodash';

import { resyncSumbit } from '../utils'
import * as Styled from './styles'

type Props = {
  menuImportStatuses: StatusResource[] | undefined
  menuMappingMenuId: string | undefined
  locationId: string | undefined
  isMenuLoading: boolean
}

const MenuImportShortcut = ({
  menuImportStatuses,
  menuMappingMenuId,
  locationId,
  isMenuLoading,
}: Props) => {
  const handleReSyncSubmit = useCallback(async () => {
    if (!menuMappingMenuId || !locationId) return
    resyncSumbit(menuMappingMenuId, locationId)
  }, [menuMappingMenuId, locationId])

  return (
    <Styled.MenuImportContainer>
      <Styled.DetailsContainer>
        <Styled.MenuContainer>
          <Styled.MenuImportHeader>Menu Import Status</Styled.MenuImportHeader>
          <ButtonWithConfirmationDialog
            title='Resync'
            description={`We’re retrieving your latest POS menu(s) and updating your ordering platforms. While this action is immediate, depending on the size of your menu(s), it may take up to an hour for your menus to publish across all platforms.`}
            okText='Resync Menus'
            isDisabled={!menuMappingMenuId}
            isDanger={false}
            onOk={handleReSyncSubmit}
          />
        </Styled.MenuContainer>
        {isMenuLoading ? (
          <SentenceLoader />
        ) : Children.toArray(_.map(menuImportStatuses, (menuImportStatus) => (
          <Styled.ImportDetailsContainer>
            <Styled.MenuImportTime>
              {menuImportStatus?.attributes.created_at ? formatDate(menuImportStatus.attributes.created_at, 'dddd, MM/DD/YYYY, h:mm A') : 'No Recent Imports'}
            </Styled.MenuImportTime>
            <Styled.StatusContainer>
              {menuImportStatus?.attributes.success !== null && (
                <>
                  <Styled.Icon src={menuImportStatus?.attributes.success ? ellipse_2_green : ellipse_2_red} alt='ellipseIcon' />
                  <Styled.Status status={menuImportStatus?.attributes.success}>{menuImportStatus?.attributes.success ? 'Success' : 'Failure'}</Styled.Status>
                </>
              )}
            </Styled.StatusContainer>
          </Styled.ImportDetailsContainer>
        )))}
      </Styled.DetailsContainer>
    </Styled.MenuImportContainer>
  )
}

export default MenuImportShortcut
