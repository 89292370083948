import * as Sentry from '@sentry/react'
import { Experiments } from 'config'
import UberEatsAuthLinkCheck from 'features/auth/UberEatsAuthLinkCheck'
import Billing from 'features/billing/Billing'
import { DesignSystemView, NotificationBannerView, ToastView } from 'features/DesignSystemView'
import DigitalMarketing from 'features/digital-marketing/DigitalMarketing'
import CreateNewMenu from 'features/manage-locations/IndividualMenu/CreateMenu/CreateNewMenu'
import ManagePlatform from 'features/manage-locations/ManagePlatform'
import DeliveryBuyDown from 'features/reporting/DeliveryBuyDown'
import DMReporting from 'features/reporting/DigitalMarketing/DigitalMarketing'
import { useAuth0 } from 'libs/auth0-react'
import { Suspense } from 'react'
import { Flags, FlagsProvider } from 'react-feature-flags'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'

import { ErrorMessage, LoadingScreen, MainLayout, NotFound, PrivateRoute } from './components'
import SignupConfirmation from './components/ManageAccount/SignupConfirmation'
import Success from './components/Success/Success'
import { appSettings, flags } from './config'
import Login from './features/auth/Login'
import Logout from './features/auth/Logout'
import Register from './features/auth/Register'
import Verify from './features/auth/Verify'
import Health from './features/health/Health'
import AddLocation from './features/manage-locations/AddLocation/AddLocation'
import IndividualLocation from './features/manage-locations/IndividualLocation/IndividualLocation'
import IndividualMenu from './features/manage-locations/IndividualMenu/IndividualMenu'
import OrderManagement from './features/order-managment/OrderManagement'
import AdvancedOrders from './features/reporting/AdvancedOrders'
import Disputes from './features/reporting/Disputes'
import Locations from './features/reporting/Locations'
import Orders from './features/reporting/Orders'
import ProductMix from './features/reporting/ProductMix'
import Reconciliation from './features/reporting/Reconciliation'
import Reporting from './features/reporting/Reporting'
import Sales from './features/reporting/Sales'
import SmartPricing from './features/reporting/SmartPricing'
import Wow from './features/reporting/Wow'
import theme from './theme'

function App() {
  const { authorizedFeatures } = useAuth0()
  const orderManagementEnabled = authorizedFeatures?.find(
    (feature) => feature[Experiments.ORDER_MANAGEMENT],
  )
  const rccPlatformEnabled = authorizedFeatures?.find(
    (feature) => feature[Experiments.RCC_PLATFORM],
  )
  const digitalMarketingEnabled = authorizedFeatures?.find(
    (feature) => feature[Experiments.DIGITAL_MARKETING],
  )

  return (
    <Sentry.ErrorBoundary fallback={ErrorMessage}>
      <FlagsProvider value={flags}>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<LoadingScreen title='Loading...' />}>
              <BrowserRouter>
                <Routes>
                  <Route path='login' element={<Login />} />
                  <Route path='logout' element={<Logout />} />
                  <Route path='verify' element={<Verify />} />
                  <Route path='register' element={<Register />} />
                  <Route path='confirmation' element={<SignupConfirmation />} />
                  <Route
                    path='doordash_success'
                    element={
                      <Success
                        title={'Doordash Authenticated'}
                        message={
                          'please notify your chowly contact that you have successfully authenticated with doordash'
                        }
                      />
                    }
                  />
                  <Route path='ubereats_auth_link_check' element={<UberEatsAuthLinkCheck />} />
                  <Route
                    path='/'
                    element={
                      <PrivateRoute>
                        <MainLayout />
                      </PrivateRoute>
                    }
                  >
                    <Route index element={<Navigate to='/' replace />}></Route>
                    <Route path='/:locationId/home' element={<Health />} />
                    <Route path='/:locationId/analytics/locations' element={<Locations />} />
                    <Route path='/:locationId/analytics/sales' element={<Sales />} />
                    <Route path='/:locationId/analytics/orders' element={<Orders />} />
                    <Route path='/:locationId/analytics/product-mix' element={<ProductMix />} />
                    <Route path='/:locationId/analytics' element={<Reporting />} />
                    <Route
                      path='/:locationId/analytics/reconciliation'
                      element={<Reconciliation />}
                    />
                    <Route path='/:locationId/analytics/disputes' element={<Disputes />} />
                    <Route path='/:locationId/analytics/smart-pricing' element={<SmartPricing />} />
                    <Route path='/:locationId/analytics/wow' element={<Wow />} />
                    <Route
                      path='/:locationId/analytics/delivery-buy-down'
                      element={<DeliveryBuyDown />}
                    />
                    <Route
                      path='/:locationId/analytics/advanced-orders'
                      element={<AdvancedOrders />}
                    />
                    <Route path='/:locationId/analytics/dm-reporting' element={<DMReporting />} />
                    <Route path='/:locationId/billing' element={<Billing />} />
                    {(rccPlatformEnabled || digitalMarketingEnabled) && (
                      <Route path='/:locationId/digital-marketing' element={<DigitalMarketing />} />
                    )}
                    {orderManagementEnabled && (
                      <Route path='/:locationId/order-management' element={<OrderManagement />} />
                    )}
                    <Route
                      path='add-location'
                      element={
                        <Flags authorizedFlags={['developmentOnly', 'stagingOnly']}>
                          <AddLocation />
                        </Flags>
                      }
                    />

                    <Route path='/:locationId/menu-management' element={<IndividualLocation />} />
                    <Route
                      path='/:locationId/menu-management/menus/:menuSectionId/preview'
                      element={<IndividualMenu />}
                    />
                    <Route
                      path='/:locationId/menu-management/menus/:menuSectionId/edit'
                      element={<IndividualMenu isEditor />}
                    />
                    <Route
                      path='/:locationId/menu-management/menus/new'
                      element={<CreateNewMenu />}
                    />
                    <Route
                      path='/:locationId/manage-platform/:platformId'
                      element={<ManagePlatform />}
                    />
                    {/* Routes for reviewing design style components */}
                    {!appSettings.isProduction && (
                      <Route path='design-system' element={<DesignSystemView />}>
                        <Route path='toast' element={<ToastView />} />
                        <Route path='notification-banner' element={<NotificationBannerView />} />
                      </Route>
                    )}

                    {/* Ensure old routes redirect to a working url */}
                    <Route path='/home' element={<Navigate to='/:locationId/home' />} />
                    <Route path='/manage-locations' element={<Navigate to='/:locationId/home' />} />
                    <Route
                      path='/manage-locations/:locationId'
                      element={<Navigate to='/:locationId/home' />}
                    />
                    <Route path='/analytics' element={<Navigate to='/:locationId/home' />} />
                  </Route>
                  <Route path='*' element={<NotFound />} />
                </Routes>
              </BrowserRouter>
              <ToastContainer autoClose={false} draggable={false} />
            </Suspense>
          </ThemeProvider>
        </RecoilRoot>
      </FlagsProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
