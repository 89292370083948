import { useField } from 'formik'

import * as Styled from './styles'

const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const hasError = Boolean(meta.touched && meta.error)
  const id = props.id ?? props.name

  if (props?.changePassword) {
    return (
      <Styled.FieldChangePassword>
        <Styled.FieldLabelChangePassword htmlFor={id}>{label}</Styled.FieldLabelChangePassword>
        <Styled.InputChangePassword id={id} {...field} {...props} hasError={hasError} />
        {hasError && <Styled.FieldErrorChangePassword>{meta.error}</Styled.FieldErrorChangePassword>}
      </Styled.FieldChangePassword>
    )
  }

  return (
    <Styled.Field>
      <Styled.FieldLabel htmlFor={id}>{label}</Styled.FieldLabel>
      <Styled.Input id={id} {...field} {...props} hasError={hasError} />
      {hasError && <Styled.FieldError>{meta.error}</Styled.FieldError>}
    </Styled.Field>
  )
}

export default TextField
