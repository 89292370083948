export type ReportingOrder = {
  id: string
  type: string
  attributes: {
    order_platform_order_id: string
    customer_email: string
    customer_first_name: string
    customer_last_name: string
    customer_phone: string
    delivery_city: string
    delivery_fee: string
    delivery_state: string
    delivery_status: string | null
    delivery_street: string
    delivery_street2: string
    delivery_zip: string
    id: string
    internal_order_id: number
    order_status: string
    order_type: OrderType.pickup | OrderType.delivery
    platform_name: string
    pos_order_id: string | null
    submission_time: string
    subtotal: string
    tax: string
    tip: string
    payment_provider: PaymentProvider.square | PaymentProvider.stripe | null
  }
}

export type Link = {
  first: string | null
  prev: string | null
  self: string | null
  next: string | null
  last: string | null
}

export type Meta = {
  limit: string
  page: number
  page_total: number
  total: number
}

export type OrderReportResponse = {
  data: ReportingOrder[]
  links: Link
  meta: Meta
}

export type ModalConfig = {
  title: (orderId: string) => string
  description: string
  submitText: string
  action: (orderId: number) => Promise<void>
}

export type ModalType = 'cancel' | 'refund'

export enum OrderType {
  pickup = 'Pick-Up',
  delivery = 'Delivery',
}

export enum PaymentProvider {
  square = 'square',
  stripe = 'stripe',
}
