import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activeMenuSectionIdState } from 'state/atoms'

const useRouteMenuSectionId = () => {
  const { menuSectionId } = useParams()
  const setActiveMenuSectionId = useSetRecoilState(activeMenuSectionIdState)

  useEffect(() => {
    if (menuSectionId) {
      setActiveMenuSectionId(menuSectionId)
    }
  }, [menuSectionId])

  return menuSectionId
}

export default useRouteMenuSectionId
