import advancedOrdersIcon from 'assets/icons/advanced-orders-report.svg'
import digitalMarketingIcon from 'assets/icons/digital-marketing.svg'
import productMixIcon from 'assets/icons/product-mix-icon.svg'
import deliveryBuyDownIcon from 'assets/icons/reports-delivery-buydown-icon.svg'
import disputesIcon from 'assets/icons/reports-disputes-icon.svg'
import locationsIcon from 'assets/icons/reports-locations-icon.svg'
import reportOrdersIcon from 'assets/icons/reports-orders-icon.svg'
import reconciliationsIcon from 'assets/icons/reports-reconciliations-icon.svg'
import salesIcon from 'assets/icons/reports-sales-icon.svg'
import smartPricingIcon from 'assets/icons/reports-smart-pricing-icon.svg'
import weekOverWeekIcon from 'assets/icons/week-over-week-icon.svg'
import { CardLink } from 'components'
import { Experiments } from 'config'
import { useAuth0 } from 'libs/auth0-react'
import { useLocation } from 'react-router-dom'

import * as Styled from './styles'

const reportingPageCardLinks = [
  {
    title: 'Locations',
    route: '/locations',
    icon: locationsIcon,
  },
  {
    title: 'Sales',
    route: '/sales',
    icon: salesIcon,
  },
  {
    title: 'Orders',
    route: '/orders',
    icon: reportOrdersIcon,
  },
  {
    title: 'Product Mix',
    route: '/product-mix',
    icon: productMixIcon,
  },
  {
    title: 'Week over Week',
    route: '/wow',
    icon: weekOverWeekIcon,
  },
  {
    title: 'Delivery Buy Down',
    route: '/delivery-buy-down',
    icon: deliveryBuyDownIcon,
  },
  {
    title: 'Smart Pricing',
    route: '/smart-pricing',
    icon: smartPricingIcon,
  },
  {
    title: 'Advanced Orders',
    route: '/advanced-orders',
    icon: advancedOrdersIcon,
    featureFlag: Experiments.ADVANCED_ORDERS_REPORT,
  },
  {
    title: 'Disputes',
    route: '/disputes',
    icon: disputesIcon,
    featureFlag: Experiments.RRR_REPORT,
  },
  {
    title: 'Reconciliation',
    route: '/reconciliation',
    icon: reconciliationsIcon,
    featureFlag: Experiments.RRR_REPORT,
  },
  {
    title: 'Digital Marketing',
    route: '/dm-reporting',
    icon: digitalMarketingIcon,
    featureFlag: Experiments.DIGITAL_MARKETING,
  },
]

function Reporting() {
  const { authorizedFeatures } = useAuth0()
  const location = useLocation()
  const { pathname } = location

  const cardsWithFullRoute = reportingPageCardLinks.map((cardInfo) => ({
    ...cardInfo,
    route: `${pathname}${cardInfo.route}`,
  }))

  return (
    <Styled.Container>
      <Styled.Grid hasThreeCols>
        {cardsWithFullRoute.map((item, i) => {
          if (!item.featureFlag) {
            return <CardLink {...item} key={i} />
          } else if (
            item.featureFlag &&
            authorizedFeatures?.find((feature) => feature[item.featureFlag])
          ) {
            return <CardLink {...item} key={i} />
          } else {
            return null
          }
        })}
      </Styled.Grid>
    </Styled.Container>
  )
}

export default Reporting
