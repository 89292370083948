import { getProvisioningStatus, updateOrderPlatform } from 'api'
import { LoadingIcon, ProvisionStatus } from 'components'
import { SubmitButton } from 'components/ModalButtons'
import { SingleSelect } from 'components/Select'
import { appSettings } from 'config'
import { Form, Formik } from 'formik'
import { useGetOrderPlatform, useRoutePlatformId } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { colors } from 'theme'
import { getMinuteOption, minuteOptions, showError } from 'utils'
import { isPosFeatureDisabled } from 'utils/PosConfig'
import { isOnboardingStatusEnabled } from 'utils/ToosConfig'

import * as Styled from './styles'

type FormValueTypes = {
  upcharge: number | null
  pickup_time_estimate: number | null
  delivery_time_estimate: number | null
}

const individualFormFields = [
  {
    name: 'upcharge',
    label: 'Upcharge',
    metric: '%',
  },
]

const timeEstimateFields = [
  {
    name: 'pickup_time_estimate',
    label: 'Pick-Up Prep Time',
    metric: 'min',
  },
  {
    name: 'delivery_time_estimate',
    label: 'Delivery Prep Time',
    metric: 'min',
  },
]

function ManagePlatform() {
  const platformId = useRoutePlatformId()
  const { user } = useAuth0()
  const isAdminUser = user?.email?.includes('@chowlyinc.com')
  const { data: platformData, loading: isLoadingPlatform } = useGetOrderPlatform(platformId)
  const isLoading = isLoadingPlatform
  const platformAttributes = platformData?.data.attributes
  const platformName = platformAttributes?.name
  const internalName = platformAttributes?.internal_name
  const initialValues: FormValueTypes = useMemo(
    () => ({
      upcharge: platformAttributes?.upcharge ?? null,
      pickup_time_estimate: platformAttributes?.pickup_time_estimate ?? null,
      delivery_time_estimate: platformAttributes?.delivery_time_estimate ?? null,
    }),
    [platformAttributes],
  )
  const partnerData = platformData?.included?.find(({ type }) => type === 'partner')
  const upchargeReadOnly = isPosFeatureDisabled('upcharge', partnerData?.attributes.pos)
  const hasStatus = platformData?.data.attributes.name.toLowerCase() === 'ubereats'
  const showTimePrepEstimates = internalName == 'Custom' || internalName == 'GoogleFo'
  const formFields = useMemo(() => {
    // Do not show time estimate fields for v2 toos
    return showTimePrepEstimates
      ? individualFormFields.concat(timeEstimateFields)
      : individualFormFields
  }, [showTimePrepEstimates])

  const [provisionStatus, setProvisionStatus] = useState<string | undefined>()
  const [provisionShowStatus, setProvisionShowStatus] = useState<boolean>(false)

  const addPlatformUserCheck = isAdminUser || appSettings.isDevelopment || appSettings.isStaging

  const showOnboardingStatus = () => {
    return isOnboardingStatusEnabled('onboarding_status', platformData?.data.attributes.name)
  }

  const fetchProvisionStatus = async () => {
    if (platformId) {
      const { data: provisioningStatusData } = await getProvisioningStatus(platformId)

      setProvisionStatus(provisioningStatusData?.attributes.aasm_state)
      setProvisionShowStatus(provisioningStatusData?.attributes.show_status)
    }
  }

  useEffect(() => {
    if (platformData && platformData.data.attributes.aasm_state !== 'disabled' && platformId) {
      fetchProvisionStatus()
    }
  }, [platformData])

  const handleSubmit = async (value: FormValueTypes) => {
    if (!platformId) return
    if (platformName) {
      try {
        await updateOrderPlatform(platformId, platformAttributes?.external_id, value)
        toast.success('Saved!')
      } catch (e) {
        showError(e)
        console.error('Unable to set location')
      }
    }
  }

  if (isLoading) {
    return <LoadingIcon color={colors.primary.dark} />
  }

  return (
    <Styled.PlatformContainer>
      <Styled.LocationName>
        {platformAttributes?.internal_name === 'Ubereats' ? 'Uber Eats' : platformAttributes?.name}
      </Styled.LocationName>
      <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Styled.FlexRow>
              <Styled.FlexContainer>
                <ProvisionStatus
                  enabled={platformAttributes?.aasm_state === 'enabled'}
                  status={platformAttributes?.aasm_state}
                  fieldName='Chowly Status'
                  fieldKey={'chowlyStatus'}
                />
                {hasStatus && provisionShowStatus && (
                  <ProvisionStatus
                    enabled={provisionStatus === 'confirmed'}
                    status={provisionStatus}
                    fieldName='Provisioning Status'
                    fieldKey={'provisionStatus'}
                  />
                )}
                {addPlatformUserCheck && showOnboardingStatus() && provisionStatus && (
                  <ProvisionStatus
                    enabled={provisionStatus === 'menu_qualified'}
                    status={provisionStatus}
                    fieldName='Onboarding Status'
                    fieldKey={'onboardingStatus'}
                  />
                )}
              </Styled.FlexContainer>

              <Styled.FlexContainer>
                {formFields.map((value, index) => (
                  <Styled.LocationFormItem key={index} data-testid={value.name}>
                    <Styled.FieldLabel>{value.label}</Styled.FieldLabel>
                    {value.metric === '%' ? (
                      platformAttributes?.has_sauce_integration ? (
                        <Styled.InputWarning>
                          This location’s upcharges are being managed dynamically through Smart
                          Pricing
                        </Styled.InputWarning>
                      ) : (
                        <Styled.InputUpchargeContainer>
                          {' '}
                          <Styled.Input
                            name={value.name}
                            type='number'
                            disabled={upchargeReadOnly}
                          />
                        </Styled.InputUpchargeContainer>
                      )
                    ) : (
                      <SingleSelect
                        options={minuteOptions}
                        placeholder=''
                        styles={{
                          container: {
                            width: 128,
                            height: 48,
                          },
                          control: {
                            width: 128,
                            height: 48,
                          },
                        }}
                        value={getMinuteOption(values[value.name as keyof FormValueTypes])}
                        onChange={(newVal) => {
                          setFieldValue(value.name, newVal !== 'N/A' ? Number(newVal) : null)
                        }}
                      />
                    )}
                  </Styled.LocationFormItem>
                ))}
              </Styled.FlexContainer>
            </Styled.FlexRow>
            <Styled.FlexRow>
              <Styled.FlexContainer></Styled.FlexContainer>
              <Styled.FlexContainer>
                <Styled.PlatformSaveBttn>
                  {!platformAttributes?.has_sauce_integration && (
                    <SubmitButton type='submit' buttonText='Save' />
                  )}
                </Styled.PlatformSaveBttn>
              </Styled.FlexContainer>
            </Styled.FlexRow>
          </Form>
        )}
      </Formik>
    </Styled.PlatformContainer>
  )
}

export default ManagePlatform
