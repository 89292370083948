import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'
import isNil from 'lodash/isNil'

import client from '../chowly'
import { 
  Invoice,
  InvoiceResponse,
} from 'api/billing/types'

export type InvoicesQueryParams = {
  partnerId: string | number | null | undefined
}

export const getInvoices = async (params: InvoicesQueryParams): Promise<Invoice[]> => {
  const url = generatePath(chowlyApi.billingInvoices)

  const { data: invoices } = await client.get<InvoiceResponse>(url, {
    params: {
      ...(isNil(params.partnerId) ? {} : { partner_id: encodeURIComponent(params.partnerId) }),
    },
  })

  return invoices?.data || []
}