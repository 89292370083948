import MetricsCarousel from 'components/MetricsCarousel/MetricsCarousel'
import { Experiments } from 'config'
import { PageContainer, ToDoContainer, ToDoTitle } from 'features/digital-marketing/styles'
import ToDo from 'features/digital-marketing/ToDo/ToDo'
// import { SmartPricing as SmartPricingCard } from './ActionCards'
import { useRouteLocationId } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'

import SalesOrderReport from './SalesOrderReport'
import { ShortCut } from './Shortcuts'
import * as Styled from './styles'

const Health = () => {
  const { authorizedFeatures } = useAuth0()
  const locationId = useRouteLocationId()
  const isRccPlatform = authorizedFeatures?.find((feature) => feature[Experiments.RCC_PLATFORM])
  const isDigitalMarketing = authorizedFeatures?.find(
    (feature) => feature[Experiments.DIGITAL_MARKETING],
  )

  return (
    <Styled.HomeContainer>
      {(isRccPlatform || isDigitalMarketing) && locationId && (
        <PageContainer style={{ padding: 0 }}>
          <ToDoContainer>
            <ToDoTitle>Digital Marketing To-Do</ToDoTitle>
            {locationId && <ToDo partnerId={locationId} />}
          </ToDoContainer>
        </PageContainer>
      )}

      {isRccPlatform && <MetricsCarousel partnerId={locationId || ''} />}
      <ShortCut locationId={locationId || ''} />
      <SalesOrderReport partnerId={locationId || ''} />
      {/* <SmartPricingCard isLoading={isLoading} /> */}
    </Styled.HomeContainer>
  )
}

export default Health
