import { useRouteLocationId } from 'hooks'

import PublishedCampaigns from './PublishedCampaigns/PublishedCampaigns'
import {
  PageContainer,
  PaidAdsContainer,
  Tab,
  Tabs,
  TabsContainer,
  ToDoContainer,
  ToDoTitle,
} from './styles'
import ToDo from './ToDo/ToDo'

const DigitalMarketing = () => {
  const partnerId = useRouteLocationId()

  return (
    <PageContainer>
      <ToDoContainer>
        <ToDoTitle>Digital Marketing To-Do</ToDoTitle>
        {partnerId && <ToDo partnerId={partnerId} />}
      </ToDoContainer>

      <TabsContainer>
        <Tabs>
          <Tab active>Paid Ads</Tab>
        </Tabs>
      </TabsContainer>

      <PaidAdsContainer>
        {partnerId && <PublishedCampaigns partnerId={partnerId} />}
      </PaidAdsContainer>
    </PageContainer>
  )
}

export default DigitalMarketing
