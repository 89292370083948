import { MenuImportStatusResponse, StatusResource } from 'api'
import { getImportMenuStatuses } from 'api/reporting/importMenuStatus'
import { useCallback, useEffect, useState } from 'react'

type UseGetMenuImportStatuses = (
  activePartnerId: string,
  limit: number,
) => [
  {
    isLoading: boolean
    isError: boolean
    menuImportStatuses?: StatusResource[]
  },
]

const useGetMenuImportStatuses: UseGetMenuImportStatuses = (
  activePartnerId?: string,
  limit?: number,
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [menuImportStatuses, setMenuImportStatuses] = useState<StatusResource[]>()

  const retrieveMenuImportStatuses = (included: MenuImportStatusResponse['data']) => {
    return included?.filter((item): item is StatusResource => item.type === 'import_menu_status')
  }
  const fetchMenuStatuses = useCallback(async () => {
    if (!activePartnerId) {
      return
    }
    setIsLoading(true)
    try {
      const params = {
        partnerId: activePartnerId,
        limit: limit,
      }
      const { data } = await getImportMenuStatuses(params)

      setMenuImportStatuses(retrieveMenuImportStatuses(data as StatusResource[]))
    } catch (err) {
      console.error(err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [activePartnerId])

  useEffect(() => {
    if (activePartnerId) {
      fetchMenuStatuses()
    }
  }, [activePartnerId])

  return [
    {
      isLoading,
      isError,
      menuImportStatuses,
    },
  ]
}

export default useGetMenuImportStatuses
